import * as Sentry from '@sentry/vue'

interface ErrorPayload {
    [x: string]: any
}
interface ImportantErrorOptionalParams {
    payload?: ErrorPayload
    originalError?: any
}

export class ImportantError extends Error {
    constructor(public message: string, optionalParams?: ImportantErrorOptionalParams) {
        super(message)
        if (!optionalParams) optionalParams = {}
        this.name = 'ImportantError'
        this.stack = optionalParams?.originalError?.stack || (<any>new Error()).stack
        Sentry.captureException(this, {
            extra: {
                ...optionalParams.payload,
                ORIGINAL_ERROR_MESSAGE: optionalParams?.originalError?.message,
                ORIGINAL_ERROR_NAME: optionalParams?.originalError?.name,
            },
        })
    }
}
