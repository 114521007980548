
import qs from 'query-string'
import { computed, defineComponent, onMounted, ref, watch, getCurrentInstance } from 'vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import useRouter from '@/hooks/useRouter'
import _ from 'lodash'
import DikidiLanding from './DikidiLanding.vue'
import { CONNECTOR_TYPES } from '../../../vars/general'
import { getInstance } from '../../../auth'
import { useAuthStore } from '../../../store/stores/auth'
export default defineComponent({
    components: { SpinnerLoader, DikidiLanding },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const { route, router } = useRouter(root)
        const connectorType = computed(() => route.value.params.connectorType)
        const authInstance = getInstance()
        const authStore = useAuthStore()

        const stepCompleted = ref(false)

        const canRedirectToConnect = () => authStore.isAuthenticated && stepCompleted.value
        const handleFastRegister = async () => {
            const authInstance = getInstance()
            const query = route.value.query
            if (query.fastReg && !authInstance.isAuthenticated) {
                if (query.fastReg === 'yClients' && query.user_data && query.user_data_sign) {
                    root.$store.commit('startLoading')
                    await authInstance.fastRegister({
                        type: query.fastReg,
                        userData: query.user_data,
                        userSign: query.user_data_sign,
                    })
                    root.$store.commit('endLoading')
                }
                if (query.fastReg === 'dikidi') {
                    root.$store.commit('startLoading')
                    await authInstance.fastRegister({
                        type: query.fastReg,
                        userData: query.user_data,
                        userSign: query.user_data_sign,
                    })
                    root.$store.commit('endLoading')
                }
            }
        }
        const redirectToConnectorInit = () => {
            const nextPath = qs.stringifyUrl({
                url: `/init/${route.value.params.connectorType}/connect`,
                query: _.omit(route.value.query, 'to'),
            })
            router.push(nextPath)
        }
        const goToNextStep = () => {
            handleFastRegister()
            stepCompleted.value = true
        }
        onMounted(() => {
            if (connectorType.value === CONNECTOR_TYPES.yClients) {
                goToNextStep()
            }
            watch(
                canRedirectToConnect,
                val => {
                    if (val) redirectToConnectorInit()
                },
                { immediate: true }
            )
        })

        return { connectorType, CONNECTOR_TYPES, redirectToConnectorInit, goToNextStep, authStore }
    },
})
