<template>
    <div>
        <div class="landing">
            <div class="banner-block">
                <v-row class="ma-0">
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text">Data Heroes </span> - аналитический сервис-помощник для
                            салонов красоты
                        </h1>
                        <p class="py-14 font-weight-regular">
                            Мы знаем, как сделать так, чтобы вы зарабатывали больше. Наш сервис позволит
                            увеличить частоту и количество посещений клиентов, в несколько раз сократить
                            расходы на привлечение новых посетителей, а также расскажет о других возможностях
                            повышения эффективности вашего бизнеса.
                        </p>
                    </div>

                    <img height="490" width="624" src="@/assets/img/landing/01.svg" />
                </v-row>

                <v-row class="ma-0">
                    <div>
                        <v-btn
                            color="main"
                            rounded
                            x-large
                            class="white--text large-btn"
                            @click="connectIntegration"
                            >Подключить</v-btn
                        >
                        <p class="mt-2 ml-12 text-body-2">Бесплатный пробный период 14 дней</p>
                    </div>
                </v-row>
            </div>

            <div class="banner-block mt-16">
                <v-row class="ma-0">
                    <img width="496" height="337" src="@/assets/img/landing/02.svg" />
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text">Data Heroes</span> - дружит с DIKIDI
                        </h1>
                        <p class="py-14 font-weight-regular">
                            По мнению наших клиентов, мы — необходимое дополнение к CRM. По нажатию одной
                            кнопки наш сервис синхронизируется с DIKIDI, и вы получаете подробную аналитику по
                            своим гостям.
                        </p>
                        <div>
                            <div>
                                <v-btn
                                    color="main"
                                    rounded
                                    x-large
                                    class="white--text large-btn"
                                    @click="connectIntegration"
                                    >Подключить</v-btn
                                >
                                <p class="mt-2 ml-12 text-body-2">Бесплатный пробный период 14 дней</p>
                            </div>
                        </div>
                    </div>
                </v-row>
            </div>
            <div class="banner-block mt-16 mb-16">
                <v-row class="ma-0">
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text"> Data Heroes</span> - отзывчивая система, которая
                            подстроится под вас
                        </h1>
                        <p class="py-14 font-weight-regular">
                            Хотите больше? Мы сделаем это для вас! Полностью погрузимся в потребности и
                            реализуем любой дополнительный отчет именно для вашего бизнеса.
                        </p>
                        <div>
                            <div>
                                <v-btn
                                    color="main"
                                    rounded
                                    x-large
                                    class="white--text large-btn"
                                    @click="connectIntegration"
                                    >Подключить</v-btn
                                >
                                <p class="mt-2 ml-12 text-body-2">Бесплатный пробный период 14 дней</p>
                            </div>
                        </div>
                    </div>

                    <img width="492" height="333" src="@/assets/img/landing/03.svg" />
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@root/src/api/api'
import { ImportantError } from '@root/src/helpers/Error'
import useRouter from '@root/src/hooks/useRouter'
import { computed, defineComponent, ref, shallowRef, watch, PropType, onMounted } from 'vue'
export default defineComponent({
    components: {},
    props: {},
    setup(props, { emit }) {
        const { route } = useRouter()
        const connectIntegration = () => {
            emit('connect-integration')
        }
        onMounted(async () => {
            const q = route.value.query
            const payload = {
                encodedUserData: q.userData,
                companyId: q.companyId,
            }
            const { data, error } = await api.pyrus.saveClient({ payload, type: 'dikidi' })
            if (error) {
                new ImportantError('dikidi mp save client', {
                    payload,
                })
            }
        })
        return { connectIntegration }
    },
})
</script>
<style scoped lang="sass">
.landing
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-top: 5%

.text-padding-top
    padding-top: 60px

.banner-block
    width: 1320px
    margin-left: 170px

.banner-block-text
    width: 600px

.test
    background-color: red
    width: 50px
    height: 50px

.large-btn
    width: 360px
</style>
